<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <LayoutProductCollection
        v-model="filterSlug"
        :title="$t('search.search_results_for', [syncedSearchQuery])"
        :filters-response="productFiltersResponse"
        :refresh-filters="refreshProductFilters"
        :sub-categories="categories"
        :loading="productFiltersStatus === 'pending' || categoriesStatus === 'pending' || articlesStatus === 'pending'"
        type="search"
    >
        <!--  CATEGORY RESULTS  -->
        <template v-if="categories?.length">
            <h2 class="vut-h2 mb-5">
                {{ $t('labels.categories') }}
            </h2>

            <div class="vut-image-menu">
                <ImageMenuCard
                    v-for="category in categories"
                    :key="category.id!"
                    :to="category.getUrl() ?? ''"
                    :image-url="category.thumbnailImageUrl ?? category.imageUrl"
                    :label="category.name"
                />
            </div>
        </template>

        <template v-if="articles?.length">
            <h2 class="vut-h2 mb-5"
                :class="{
                    'mt-5': categories?.length,
                }"
            >
                {{ $t('labels.blog') }}
            </h2>

            <div class="vut-image-menu">
                <ImageMenuCard
                    v-for="article in articles"
                    :key="article.id!"
                    :to="article.getUrl() ?? ''"
                    :image-url="article.imageUrl"
                    :label="article.name"
                />
            </div>
        </template>
    </LayoutProductCollection>
</template>

<script lang="ts" setup>
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { param: searchQuery, syncedParam: syncedSearchQuery, syncQueryParam } = useDynamicRouteQueryParam('search', 'search_query')

if (!searchQuery.value) {
    throw createError({
        statusCode: 404,
        fatal: true,
    })
}

const route = useRoute()
const filterSlug = ref<string>(route.fullPath)

const [
    { data: productFiltersResponse, refresh: refreshProductFilters, status: productFiltersStatus },
    { items: categories, execute: fetchCategories, status: categoriesStatus },
    { items: articles, execute: fetchArticles, status: articlesStatus },
] = await Promise.all([
    useProductsFilterApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .usePost({ request_uri: filterSlug }, { watch: false }),
    // TODO: replace with search results endpoint
    useCategoriesApiService()
        .whereStartsWith(CategoryModel.ATTR_NAME, () => searchQuery.value ?? '')
        .sortByAsc(CategoryModel.ATTR_POSITION)
        .embed([
            CategoryModel.EMBED_URLS,
            CategoryModel.EMBED_IMAGE_URL,
            CategoryModel.EMBED_THUMBNAIL_IMAGE_URL,
        ])
        .onlyAttrs([
            CategoryModel.ATTR_ID,
            CategoryModel.ATTR_NAME,
        ])
        .useGet({
            watch: false,
        }),
    useArticlesApiService()
        .whereStartsWith(ArticleModel.ATTR_NAME, () => searchQuery.value ?? '')
        .embed([
            ArticleModel.EMBED_URLS,
            ArticleModel.EMBED_IMAGE_URL,
            ArticleModel.EMBED_THUMBNAIL_IMAGE_URL,
        ])
        .onlyAttrs([
            ArticleModel.ATTR_ID,
            ArticleModel.ATTR_NAME,
        ])
        .useGet({
            watch: false,
        }),
])

watch(() => searchQuery.value, async () => {
    if (!searchQuery.value) return
    filterSlug.value = route.fullPath
    await Promise.all([
        refreshProductFilters(),
        fetchCategories(),
        fetchArticles(),
    ])

    syncQueryParam()
})

</script>

<style scoped lang="scss">
</style>
